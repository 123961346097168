.footer-container {
  display: flex;
  align-items: center;
}

@media (min-width: 769px) {
  .footer-container {
    display: flex;
    align-items: flex-start;
  }
}
