.link:hover {
    text-decoration: none !important;
}

.center {
    margin: 0 auto !important;
}

.top50 {
    margin-top: 50px !important;
}

.center-text {
    text-align: center;
}